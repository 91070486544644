import ApplicationController from './application_controller'

export default class extends ApplicationController {
  afterReflex (element, reflex, error) {
    if (element.dataset.act == 'open') {
      element.dataset.act = 'close'
    } else {
      element.dataset.act = 'open'
      element.querySelector('div').innerHTML = ''
    }
  }
}
