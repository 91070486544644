// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("intersection-observer")
require("trix")
require("@rails/actiontext")

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@stimulus_reflex/polyfills'
import "controllers"
import "../stylesheets/application.scss"
import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap.css"
import { addBackToTop } from "vanilla-back-to-top"

document.addEventListener("turbolinks:load", function() {
    addBackToTop({
        diameter: 46,
        backgroundColor: '#007bff',
        textColor: '#fff'
    })
})
